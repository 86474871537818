

























import { Vue, Component } from "vue-property-decorator";
import AdminCards from "../admin/subComponents/AdminCards.vue";
import AdminPie from "../admin/subComponents/AdminPie.vue";
import AdminFilters from "../../components/UIComponents/Admin/Filters.vue";
import AdminBarcharts from "../admin/subComponents/AdminBarcharts.vue";
import { IncidentModule, ReportModule, MetaModule } from "../../store/modules";

@Component({
  components: {
    AdminFilters,
    AdminCards,
    AdminPie,
    AdminBarcharts,
  },
})
export default class Admin extends Vue {
  async mounted() {
    MetaModule.loadIncidentCategory();
    MetaModule.loadDis();
  }
}
